<template>

  <!-- Лента пользователя -->
  <v-container id="CancellationOrderT" class="h-100">

    <div class="back-button text-right">
      <v-btn icon @click="goBack">
        <v-icon color="grey darken-2">mdi-chevron-down</v-icon>
      </v-btn>
    </div>

    <div class="pb-3 mb-3">
      <div class="top-text my-3">
        <h1 class="title is-1 thin black--text">Что</h1>
        <h1 class="title is-1 black--text">везём?</h1>
      </div>
    </div>

    <div class="remaining-space">
      <p class="title is-4 mb-0">
        Вы уверены, что хотите отменить этот заказ?
        <br>Это стоит делать только в случае, если заказ является фейковым
        или клиент совсем не выходит на связь.
        <br>Вернуть его будет невозможно
      </p>
    </div>

    <div class="pa-3 pb-0" style="text-align: center;">
      <p class="buttons mb-0 flex justify-center align-center">
        <b-button @click="cancellationOrder" class="w-100" type="is-danger is-light">
          Отменить
        </b-button>
      </p>
      <p class="buttons mb-0 flex justify-center align-center">
        <b-button @click="goBack" class="w-100" type="is-info is-light">
          Не отменять
        </b-button>
      </p>
    </div>

    <FullScreenLoader :show="isLoading" />

  </v-container>

</template>

<script>
export default {
  name: 'CancellationOfOrderTransporter',
  data() {
    return {
      isLoading: false
    };
  },
  created() {
  },
  computed: {
    cancel_data() {
      return this.$store.getters.CANCEL_DATA;
    }
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    cancellationOrder() {
      this.isLoading = true;
      this.$store.dispatch('CHANGE_STATUS', this.cancel_data).then(() => {
        this.$buefy.toast.open({
          message: 'Заказ отменен',
          type: 'is-success'
        });
        this.$router.push('/activity-feed');
      }).catch(() => {
        this.$buefy.toast.open({
          message: 'Ошибка отмены заказа',
          type: 'is-danger'
        })
      }).finally(() => {
        this.isLoading = false;
      })
    },
  },
};
</script>

<style scoped>
.block-content img {
  max-width: 100%;
  height: auto;
}

.title.is-1 {
  font-size: 4.5rem !important;
}

.title.is-1.thin {
  font-weight: 300;
}

#CancellationOrderT.container {
  display: flex;
  flex-direction: column;
}

.remaining-space {
  flex-grow: 1;
}

.title.is-4 {
  margin-bottom: 0px !important;
  line-height: normal !important;
}

</style>
